import $ from 'jquery';
// import 'slick-carousel';

'use strict';

// PC/SP判定
// スクロールイベント
// リサイズイベント
// スムーズスクロール

/* ここから */
var mql = window.matchMedia('screen and (max-width: 640px)');//、MediaQueryListの生成
var deviceFlag = mql.matches ? 1 : 0; // 0 : PC ,  1 : SP

// pagetop
var timer = null;
var $pageTop = $('#pagetop');
$pageTop.hide();

// スクロールイベント
$(window).on('scroll touchmove', function () {

  // スクロール中か判定
  if (timer !== false) {
    clearTimeout(timer);
  }

  // 200ms後にフェードイン
  timer = setTimeout(function () {
    if ($(this).scrollTop() > 100) {
      $('#pagetop').fadeIn('normal');
    } else {
      $pageTop.fadeOut();
    }
  }, 200);

  var scrollHeight = $(document).height();
  var scrollPosition = $(window).height() + $(window).scrollTop();
  var footHeight = parseInt($('#footer').innerHeight());

  var diff = (15 + 20);

  scrollHeight - scrollPosition <= footHeight - diff
    ? $pageTop.removeClass('is-fixed')
    : $pageTop.addClass('is-fixed');
});


// リサイズイベント
var checkBreakPoint = function (mql) {
  deviceFlag = mql.matches ? 1 : 0;// 0 : PC ,  1 : SP
  // → PC
  if (deviceFlag === 0) {
    if(nav) nav.reset();
  }
  // →SP
  else {}

  deviceFlag = mql.matches;
}

// ブレイクポイントの瞬間に発火
mql.addListener(checkBreakPoint);//MediaQueryListのchangeイベントに登録

// 初回チェック
checkBreakPoint(mql);


// スムーズスクロール
// #で始まるアンカーをクリックした場合にスムーススクロール
$('a[href^="#"]').on('click', function () {
  var speed = 500;
  // アンカーの値取得
  var href = $(this).attr('href');
  // 移動先を取得
  var target = $(href == '#' || href == '' ? 'html' : href);
  // 移動先を数値で取得
  var position = target.offset().top;
  position -= $('#header').height();

  // スムーススクロール
  $('body,html').animate({
    scrollTop: position
  }, speed, 'swing');
  return false;
});



/*
** ナビゲーション
*/
var nav = (function() {
  var cls = 'is-active';
  var obj = {
    init: function() {
      var _this = this;
      this.$btn = $('.js-nav');
      this.$close = $('.js-nav-close');
      this.$target = $('.js-nav-target');
      this.touchstartY = null;
      this.event = this.event.bind(this);
      this.$btn.on('click', function() {
        $(this).hasClass(cls) ?
          _this.off() : _this.on();
      });
      this.$close.on('click', function() {
        _this.off();
      });
      window.addEventListener('touchstart', function(e) {
        _this.touchstartY = e.changedTouches[0].screenY;
      });
      return this;
    },
    on: function() {
      this.$btn.addClass(cls);
      this.$target.addClass(cls);
      this.fixed();
    },
    off: function() {
      this.$btn.removeClass(cls);
      this.$target.removeClass(cls);
      this.moved();
    },
    event: function(e) {
      var $t = this.$target[0];
      var obj = {
        height: Math.ceil($t.offsetHeight),
        currentY: e.changedTouches[0].screenY
      };
      var direction = {
        top: this.touchstartY <= obj.currentY,
        bottom: this.touchstartY >= obj.currentY
      }
      var isFlag = {
        top: direction.top && $t.scrollTop === 0,
        bottom: direction.bottom && $t.scrollHeight - $t.scrollTop === obj.height
      };
      if (obj.height === $t.scrollHeight || e.cancelable && (isFlag.top || isFlag.bottom)) {
        e.preventDefault();
        console.log(1);
      }
    },
    fixed: function() {
      $('html, body').css('overflow', 'hidden');
      window.addEventListener('touchmove', this.event, {
        passive: false
      });
    },
    moved: function() {
      $('html, body').css('overflow', '');
      window.removeEventListener('touchmove', this.event, {
        passive: false
      });
    },
    reset: function() {
      $('html, body').removeAttr('style');
      this.$target.removeClass('style');
      this.$btn.removeClass(cls);
      this.moved();
    }
  }
  return obj.init();
})();



/*
** header調整
*/
(function() {
  var scrollPoint = 120;
  var targetPoint = 30;
  var scrollCls = 'is-color';
  var targetCls = 'is-fixed';
  var $header = $('#header');
  var _scroll = function() {
    var st = $(window).scrollTop();

    // headerに背景色を付ける
    scrollPoint <= st
      ? $header.addClass(scrollCls)
      : $header.removeClass(scrollCls);

    // スマホ時一定量のスクロールでheaderを追従
    if(!deviceFlag) return;
    targetPoint <= st
      ? $header.addClass(targetCls)
      : $header.removeClass(targetCls);
  }
  $(window).on('scroll', _scroll);
  _scroll();
})();

